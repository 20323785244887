body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 64px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
  margin-bottom: 0;
}

pre {
  margin-top: 4px;

  border-radius: 8px;
  border: 1px solid #ccc;
  padding: 16px;
  white-space: pre-wrap;       /* Since CSS 2.1 */
  white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
  white-space: -pre-wrap;      /* Opera 4-6 */
  white-space: -o-pre-wrap;    /* Opera 7 */
  word-wrap: break-word;       /* Internet Explorer 5.5+ */
}

.starter {
  background-color: #f0f0f0;
  border: none;
  border-radius: 4px;
  &:hover {
    background-color: #ccc;
    cursor: pointer;
  }
  margin: 4px 0px;
  padding: 4px;
  text-align: left;
}

.container {
  margin: auto;
  max-width: 600px;
}